<template>
  <div>
    <h2> ลูกค้า</h2>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px">
          <v-card-text>
            <h3
              class="text-center"
            >
              จำนวนลูกค้า
            </h3>

            <Pie
              :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
              :options="options"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <!--      <v-col-->
      <!--        cols="12"-->
      <!--        sm="6"-->
      <!--        md="6"-->
      <!--      >-->
      <!--        <v-card style="min-height: 180px;">-->
      <!--          <v-card-text>-->
      <!--            <h3-->
      <!--              class="text-center"-->
      <!--            >-->
      <!--              แนวโน้ม-->
      <!--            </h3>-->

      <!--            <LineChartGenerator-->
      <!--              :chart-options="chartOptions"-->
      <!--              :chart-data="chartDataTop"-->
      <!--              :chart-id="chartId"-->
      <!--              :dataset-id-key="datasetIdKey"-->
      <!--              :plugins="plugins"-->
      <!--              :css-classes="cssClasses"-->
      <!--              :styles="styles"-->
      <!--              :width="width"-->
      <!--              :height="height"-->
      <!--            />-->

      <!--            &lt;!&ndash;            <Bar&ndash;&gt;-->
      <!--            &lt;!&ndash;              :chart-options="chartOptions"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :chart-data="chartData"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :chart-id="chartId"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :dataset-id-key="datasetIdKey"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :plugins="plugins"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :css-classes="cssClasses"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :styles="styles"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :width="width"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :height="height"&ndash;&gt;-->
      <!--            &lt;!&ndash;            />&ndash;&gt;-->
      <!--          </v-card-text>-->
      <!--        </v-card>-->
      <!--      </v-col>-->
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-data-table
          :headers="headers"
          :items="reports"
          :search="search"
          :server-items-length="total"
          :options.sync="serverOptions"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- <v-toolbar-title> -->
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="text-center"
                  @click="download_excel"
                >
                  Download Excel
                </v-btn>
              </v-col>
              <!-- </v-toolbar-title> -->
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->

              <!--              <v-dialog-->
              <!--                v-model="dialogDelete"-->
              <!--                max-width="600px"-->
              <!--              >-->
              <!--                <v-card>-->
              <!--                  <v-card-title class="text-h5 mb-5">-->
              <!--                    Are you sure you want to delete this item?-->
              <!--                  </v-card-title>-->
              <!--                  <v-card-actions>-->
              <!--                    <v-spacer></v-spacer>-->
              <!--                    <v-btn-->
              <!--                      color="error"-->
              <!--                      outlined-->
              <!--                      @click="closeDelete"-->
              <!--                    >-->
              <!--                      Cancel-->
              <!--                    </v-btn>-->
              <!--                    <v-btn-->
              <!--                      color="primary"-->
              <!--                      @click="deleteItemConfirm"-->
              <!--                    >-->
              <!--                      OK-->
              <!--                    </v-btn>-->
              <!--                    <v-spacer></v-spacer>-->
              <!--                  </v-card-actions>-->
              <!--                </v-card>-->
              <!--              </v-dialog>-->
            </v-toolbar>
          </template>
          <template v-slot:item.index="{ index }">
            {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
          </template>
          <template v-slot:no-data>
            <!-- <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import Swal from 'sweetalert2'

import { Bar, Pie, Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
  PointElement,
  LineElement,
} from 'chart.js'

import instance_member from '@/services/member'

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'ValidName',
  components: {
    Swal,
    Bar,
    Pie,
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
      },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    page: 1,
    size: 10,
    oldMember: 0,
    newMember: 0,
    testData: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [40, 39, 10, 40, 39, 80, 40],
        },
      ],
    },
    chartDataTop: {
      labels: [
        '',
        'มิ.ย./ 2566',
        'ก.ค./ 2566',
        'ส.ค./ 2566',
        'ก.ย./ 2566',
        '',
      ],
      datasets: [
        {
          label: 'ลูกค้าใหม่',
          data: [null, 20, 15, 30, 50, null],
          lineTension: 0.6,
          backgroundColor: '#3E80E1',
          borderColor: '#3E80E1',
          pointRadius: 3,
        },
        {
          label: 'ลูกค้าซื้อซ้ำ',
          data: [null, 10, 5, 50, 20, null],
          lineTension: 0.6,
          backgroundColor: '#FF0000',
          borderColor: '#FF0000',
          pointRadius: 3,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
    configs: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    chartData: {
      labels: ['ลูกค้าใหม่', 'ลูกค้าซื้อซ้ำ'],
      datasets: [
        {
          label: 'ลูกค้าใหม่',
          backgroundColor: ['#f87979', '#3E80E1'],
          data: [],
        },
      ],

    },
    chartOptions: {
      responsive: true,
    },
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    reports: [],
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลูกค้า',
        value: 'member_name',
      },
      {
        text: 'ยอดซื้อ (บาท)',
        value: 'expense',
        sortable: false,
      },
      {
        text: 'ยอดซื้อ (%)',
        value: 'expense_percent',
        sortable: false,
      },
    ],
    report: {},
    editedIndex: -1,
    date: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getReportTable()
      },
    },
    search: {
      handler() {
        this.getReportTable()
      },
    },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // const date = new Date()
    // const day = date.getDate()
    // const last_month = date.getMonth()
    // const month = date.getMonth() + 1
    // const year = date.getFullYear()
    //
    // // this.date = `${year}-${String(month).padStart(2, '0')}-${day}`;
    //
    // this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`)
    // this.date.push(`${year}-${String(month).padStart(2, '0')}-${day}`)
    this.getReport()
    this.getReportTable()
  },

  methods: {
    download_excel() {
      const url = `${process.env.VUE_APP_BASEURL}/exports_table_member_csv?search=${this.search}&date=${this.date}`
      window.open(url, '_blank', 'noreferrer')
    },
    editItem(item) {
      this.editedIndex = this.configs.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    getReport() {
      this.$store.state.isLoading = true
      const data = {
        search: '',
        date: this.date,
        type: '',
        sortBy: '',
        sortDesc: '',
        size: '',
        page: '',
      }

      instance_member.getReportMember(data)
        .then(res => {
          // this.report = res.data.data.members
          // this.chartData.datasets.data.push(res.data.data.new)
          // this.chartData.datasets.data.push(res.data.data.old)

          const membersData = []
          membersData.push(res.data.data.new)
          membersData.push(res.data.data.old)
          this.chartData = {
            labels: ['ลูกค้าใหม่', 'ลูกค้าซื้อซ้ำ'],
            datasets: [
              {
                label: 'ลูกค้าใหม่',
                backgroundColor: ['#f87979', '#3E80E1'],
                data: membersData,
              },
            ],

          }

          const lbs = []
          const vals = []

          // res.data.data.last_register
          this.chartDataTop = {
            labels: [
              '',
              'มิ.ย./ 2566',
              'ก.ค./ 2566',
              'ส.ค./ 2566',
              'ก.ย./ 2566',
              '',
            ],
            datasets: [
              {
                label: 'ลูกค้าใหม่',
                data: [null, 20, 15, 30, 50, null],
                lineTension: 0.6,
                backgroundColor: '#3E80E1',
                borderColor: '#3E80E1',
                pointRadius: 3,
              },
              {
                label: 'ลูกค้าซื้อซ้ำ',
                data: [null, 10, 5, 50, 20, null],
                lineTension: 0.6,
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
                pointRadius: 3,
              },
            ],
          }

          console.log(this.chartData, 9999)

          // this.report = res.data.data.old
          // this.report = res.data.data.new
          this.$store.state.isLoading = false
        })
        .catch(err => {
          this.$store.state.isLoading = false
        })
    },
    getReportTable() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search ? this.search : '',
        date: '',
        status: '',
        type: '',
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
      }

      instance_member.getReportMemberTable(data)
        .then(res => {
          this.reports = res.data.data.data
          this.total = res.data.data.total
          this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
          this.serverOptions.page = res.data.data.current_page
          this.$store.state.isLoading = false
        })
        .catch(err => {
          this.$store.state.isLoading = false
        })
    },
    openLink(item) {
      // date=2023-05-24&date=2023-06-24
      // 2023/05/24%20-%202023/06/24
      this.$router.push({
        name: item.to.name,
        query: {
          search: item.search,
          status: item.status_th,
          date: this.date.toString(),
        },
      })

      // router.push(})
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
